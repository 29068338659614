import { Link } from "gatsby"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import React, { Component } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import "./Header.css"
class HeaderSource extends Component {
  render() {
    const active = {
      color: "rgb(255, 255, 255) !important",
      borderBottomColor: "rgb(255, 255, 255)",
    }
    return (
      <Navbar
        collapseOnSelect
        expand="md"
        className="nav-masthead"
        fixed="top"
        variant="dark"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className=" navbar-collapse justify-content-md-center">
            <ul className="navbar-nav">
              <li>
                <Navbar.Brand className="p-0 margin-auto nav-logo" href="/">
                  <img src={this.props.data.contentfulHeader.logo.file.url} />
                </Navbar.Brand>
              </li>
              <Nav>
                <li>
                  <Link activeStyle={active} className="nav-link" to="/">
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link
                    activeStyle={active}
                    className="nav-link"
                    to="/servicios"
                  >
                    Servicios
                  </Link>
                </li>
                <li>
                  <Link
                    activeStyle={active}
                    className="nav-link"
                    to="/nosotros"
                  >
                    Nosotros
                  </Link>
                </li>
                <li>
                  <Link
                    activeStyle={active}
                    className="nav-link"
                    to="/vacantes"
                  >
                    Vacantes
                  </Link>
                </li>
                <li>
                  <Link
                    activeStyle={active}
                    className="nav-link"
                    to="/contacto"
                  >
                    Contacto
                  </Link>
                </li>
              </Nav>
            </ul>
          </div>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

const Header = props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        contentfulHeader(type: { eq: "standard" }) {
          logo {
            file {
              url
            }
          }
          links {
            url
            text
          }
        }
      }
    `}
    render={data => <HeaderSource data={data} {...props} />}
  />
)

export default Header
